import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Background from '../images/background2.svg'

const StyledHeader = styled.header`

  background-image: url(${props => props.back});
  background-repeat: no-repeat;
  background-size: cover;
  background-color:#b7dbbd;
  width: 100%;
  height: 25vh;
  display: flex;
  
  

  nav {
      
      flex: 1;
      margin-top: 0.5em;
      
  }

  ul {
    
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin: 0px;
    
  }

  a,li{
    list-style: none;
    color: #5f5f79;
    font-size: 1rem;
    text-decoration: none;
  }

  a.current, a:hover {
    color: #DD1C1A;
    
  }

  


  
  


`

const Header = () => (
  <StyledHeader back = {Background} >

    
          <nav id="top">
            <ul>
              <li><Link to="/" activeClassName="current" >Home</Link></li>
              <li><Link to="/About/" activeClassName="current">About me</Link></li>
              <li><Link to="/MyWork/" activeClassName="current"> My Work</Link></li>
              <li><Link to="/Contact/" activeClassName="current">Contact</Link></li>
            </ul>
          </nav>
        
  </StyledHeader>
)



export default Header
