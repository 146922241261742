import  { createGlobalStyle, keyframes } from 'styled-components'



const heading = keyframes`
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }



`



const GlobalStyles = createGlobalStyle`
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body {
    background-color: #b7dbbd;
}

.expand {
    animation: ${heading} 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }





`




export default GlobalStyles